import { ethers } from "ethers";
import rootProxyAbi from "./abis/rootProxyRegistry.json";
import requiredNftAbi from "./abis/requiredNFT.json";
import walifyNftAbi from "./abis/walifyNFT.json";



// let root_proxy_contract = "0xEA517cD87CDDD94eF768b8D8358d30dF656071D8"
let root_proxy_contract = "0x890288DddE7282A06720D8848878C98CfC7eB694"
let required_nft_contract = "0x9984356eC8D9472B52ed510FD5243Ffe755a8d5D"
// let walify_nft_contract = "0x331acfA0Ad2854F5933aa8a2DC5af862Ab9E90BA"
let walify_nft_contract = "0xA3c81AAb27e029CD3F2faB1fCA588cFDf826561c"
let price = "1000000000000000";

export const signMessage2 = async (library,account) => {
    if (!library) return;
    try {
      const normal = ethers.utils.getAddress(account);
      console.log(normal)
      const signature = await library.provider.request({
        method: "personal_sign",
        params: ["hello world", account]
      });
      console.log(signature)
    //   setSignedMessage("hello world");
    //   setSignature(signature);
    } catch (error) {
      console.log(error);
    }
  };

export const register = async (library,proxy) => {
    if (!library){ console.log("oops"); return false;}
    try {
     let signer = library.getSigner();
     
      const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,signer)
    //   console.log(register_contract)
      let result = await register_contract.register(proxy);
      console.log("what occured", result);
      let txresult = await result.wait();
      console.log("what occured2", txresult);
    //   const signature = await library.provider.request({
    //     method: "personal_sign",
    //     params: ["hello world", account]
    //   });
    //   console.log(signature)
    //   setSignedMessage("hello world");
    //   setSignature(signature);
    } catch (error) {
      // console.log(error);
      return error;
    }
    return true;
  };


export const mintRequiredWeb3 = async (library,proxy) => {
  if (!library){ console.log("oops"); return false;}
  try {
   let signer = library.getSigner();
   
    const nft_contract = new ethers.Contract(required_nft_contract,requiredNftAbi,signer)

    let result = await nft_contract.publicMint(1,
      {value: price});
    console.log("what occured", result);
    let txresult = await result.wait();
    console.log("what occured2", txresult);

  } catch (error) {

    return error;
  }
  return true;
};

export const mintWalifyRootWeb3 = async (library,proxy) => {
  if (!library){ console.log("oops"); return false;}
  try {
   let signer = library.getSigner();
   
    const nft_contract = new ethers.Contract(walify_nft_contract,walifyNftAbi,signer)
    console.log("hyere");
    let result = await nft_contract.publicMint(1,
      {value: price});
    console.log("what occured", result);
    let txresult = await result.wait();
    console.log("what occured2", txresult);

  } catch (error) {
    console.log(error)
    return error;
  }
  return true;
};

export const mintWalifyProxyWeb3 = async (library,proxy) => {
  if (!library){ console.log("oops"); return false;}
  try {
   let signer = library.getSigner();
      //  function giveRoot(address proxy) 
  const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,library)
  let root = await register_contract.giveRoot(proxy);
  console.log(root)
   const nft_contract = new ethers.Contract(walify_nft_contract,walifyNftAbi,signer)
    console.log("getting here 4")
    let result = await nft_contract.proxyPublicMint(1,root, 
      {value: price});
    console.log("what occured", result);
    let txresult = await result.wait();
    console.log("what occured2", txresult);

  } catch (error) {

    return error;
  }
  return true;
};


export const mintWalifyProxyToRootWeb3 = async (library,proxy) => {
  if (!library){ console.log("oops"); return false;}
  try {
   let signer = library.getSigner();
      //  function giveRoot(address proxy) 
  const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,library)
  let root = await register_contract.giveRoot(proxy);
  console.log(root)
   const nft_contract = new ethers.Contract(walify_nft_contract,walifyNftAbi,signer)
    console.log("getting here 4")
    let result = await nft_contract.proxyPublicMintToRoot(1,root, 
      {value: price});
    console.log("what occured", result);
    let txresult = await result.wait();
    console.log("what occured2", txresult);

  } catch (error) {
    console.log(error)
    return error;
  }
  return true;
};


export const registerWithProofWeb3 = async (library,proxy) => {
  if (!library){ console.log("oops"); return false;}
  try {
   let signer = library.getSigner();
   
    const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,signer)
  //   console.log(register_contract)
    let result = await register_contract.registerWithProof(proxy);
    console.log("what occured", result);
    let txresult = await result.wait();
    console.log("what occured2", txresult);
  //   const signature = await library.provider.request({
  //     method: "personal_sign",
  //     params: ["hello world", account]
  //   });
  //   console.log(signature)
  //   setSignedMessage("hello world");
  //   setSignature(signature);
  } catch (error) {
    // console.log(error);
    return error;
  }
  return true;
};

  export const checkForProxy = async (library,account) => {
    console.log("here1")
    let result;
    if (!library){ console.log("oops"); return false;}
    try {
      console.log("here2", account, library)
    //  let signer = library.getSigner();
      const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,library)
    //   console.log(register_contract)
      result = await register_contract.giveProxy(account);
      console.log(result);
      console.log("here3")
    //   const signature = await library.provider.request({
    //     method: "personal_sign",
    //     params: ["hello world", account]
    //   });
    //   console.log(signature)
    //   setSignedMessage("hello world");
    //   setSignature(signature);
    } catch (error) {
      console.log(error);
      return error;
    }
    if (ethers.constants.AddressZero == result){
      return "";
    }
    return result;
  };


  export const checkForRoot= async (library,account) => {
    console.log("here1")
    let result;
    if (!library){ console.log("oops"); return false;}
    try {
      
    //  let signer = library.getSigner();
      const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,library)
    //   console.log(register_contract)
      result = await register_contract.giveRoot(account);
      console.log(result);
      console.log("here give root")
    //   const signature = await library.provider.request({
    //     method: "personal_sign",
    //     params: ["hello world", account]
    //   });
    //   console.log(signature)
    //   setSignedMessage("hello world");
    //   setSignature(signature);
    } catch (error) {
      console.log(error);
      return error;
    }
    if (ethers.constants.AddressZero == result){
      return "No Root Registered";
    }
    return result;
  };


  export const verifyRootProxyWeb3 = async (library,account,root,proxy) => {
    
    // let address = await library.resolveName('alice.eth');

    // console.log("here1",address)

    let result;
    if (!library){ console.log("oops"); return false;}
    try {
      console.log("here2", account, library)
    //  let signer = library.getSigner();
      const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,library)
    //   console.log(register_contract)
      console.log("here3")
      result = await register_contract.verify(root,proxy);
      console.log("result",result);
      console.log("here4")
    //   const signature = await library.provider.request({
    //     method: "personal_sign",
    //     params: ["hello world", account]
    //   });
    //   console.log(signature)
    //   setSignedMessage("hello world");
    //   setSignature(signature);
    } catch (error) {
      console.log(error);
      return error;
    }

    return result;
  };
  export const proveGhostWeb3 = async (library,ghost) => {
      if (!library){ console.log("oops"); return false;}
      try {
       let signer = library.getSigner();
       
        const register_contract = new ethers.Contract(root_proxy_contract,rootProxyAbi,signer)
      //   console.log(register_contract)
        let result = await register_contract.proveProxy(ghost);
        console.log("what occured", result);
        let txresult = await result.wait();
        console.log("what occured2", txresult);
      //   const signature = await library.provider.request({
      //     method: "personal_sign",
      //     params: ["hello world", account]
      //   });
      //   console.log(signature)
      //   setSignedMessage("hello world");
      //   setSignature(signature);
      } catch (error) {
        // console.log(error);
        return error;
      }
      return true;
    };