import { useEffect, useState } from "react";
import {
  VStack,
  Button,
  Text,
  HStack,
  Select,
  Input,
  Flex,
  Spacer,
  Image,
  Box,
  Container,
  Center,
  Link
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import { networkParams } from "./networks";
import { toHex, truncateAddress, truncateGhost } from "./utils";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "./providerOptions";
import "./styles.css";
import {signMessage2,
  register,
  mintRequiredWeb3,
  mintWalifyRootWeb3,
  mintWalifyProxyWeb3,
  mintWalifyProxyToRootWeb3,
  checkForProxy,
  checkForRoot,
  verifyRootProxyWeb3,
  proveGhostWeb3,
  registerWithProofWeb3
} from "./Web3.js";

import {CopyToClipboard} from 'react-copy-to-clipboard';

import Spinner from "./spinner"

// https://v1.chakra-ui.com/docs/components/layout/flex
const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions // required
});

export default function Home() {
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [started, setStarted] = useState(false);
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [regError, setRegError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();
  const [sequence, setSequence] = useState("mint_required");
  const [proxy, setProxy] = useState("");
  const [curProxy, setCurProxy] = useState("");
  const [copyMessage, setCopyMessage] = useState("");
  const [verifyProxy, setVerifyProxy] = useState("");
  const [verifyRoot, setVerifyRoot] = useState("");
  const [verifySuccess, setVerifySuccess] = useState("");
  const [verifySuccessColor, setVerifySuccessColor] = useState("green");
  const [verifyError, setVerifyError] = useState("");
  const [proveGhostAddress, setProveGhostAddress] = useState("");
  const [registerSuccess, setRegisterSuccess] = useState("");
  const [proveSuccess, setProveSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const [currentRoot,setCurRoot] = useState("");


  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      
      setProvider(provider);
      setLibrary(library);

      if (accounts) setAccount(accounts[0]);
      setChainId(network.chainId);
      // console.log("Yo",library,accounts[0])
      let curProxy = await checkForProxy(library,accounts[0])
      
    } catch (error) {
      setError(error);
    }

  };

  const handleNetwork = (e) => {
    const id = e.target.value;
    setNetwork(Number(id));
  };

  const handleInputProxy = (e) => {
    const proxy = e.target.value;
    console.log(proxy)
    setProxy(proxy);
    setRegisterSuccess("")
  };

  const handleInputVerifyProxy = (e) => {
    const verifyProxy = e.target.value;
    console.log(verifyProxy)
    setVerifyProxy(verifyProxy);
  };

  const handleInputVerifyRoot = (e) => {
    const verifyRoot = e.target.value;
    console.log(verifyRoot)
    setVerifyRoot(verifyRoot);
  };

  const handleInputProveGhost = (e) => {
    const proveGhost = e.target.value;
    console.log(proveGhost)
    setProveGhostAddress(proveGhost);
    setRegisterSuccess("")
  };

  
  const handleSequence = (e) => {
    const the_id = e.target.id;
    console.log(the_id)
    setSequence(the_id)
    setRegisterSuccess("")
    setProveSuccess("")
    setRegError("")
    setVerifyError("")
    setVerifySuccess("")
    const non_advanced = ["register","verify"]
    if (non_advanced.includes(the_id)){
      setAdvanced(false)
    }
  };

  const handleAdvanced = (e) => {
    console.log("advanced activated")
    setAdvanced(true);
    setSequence("prove")
  };

  

  const mintRequired = async (e) =>{
    setLoading(true)
    setRegError("")
    let result = await mintRequiredWeb3(library,proxy)
    console.log(result)
    if (result.error){
      console.log(result.error.message)
      setRegError(result.error.message)
    }
    else if (result.message){
      console.log(result.message)
      if (result.message.startsWith("invalid address",0)){
        setRegError("invalid address")
      }
      else(
        setRegError(result.message)
      )

    }
    try{
      let curRoot = await checkForRoot(library,account)
      setCurRoot(curRoot)
      let curProxy = await checkForProxy(library,account)
      setCurProxy(curProxy)

    }
    catch(err){
      console.log(err)
    }
    if (result === true){
      setRegisterSuccess("Success")
    }
    setLoading(false)

  }


  const registerProxyWithProof = async (e) =>{
    setLoading(true)
    setRegError("")
    let result = await registerWithProofWeb3(library,proxy)
    console.log(result)
    if (result.error){
      console.log(result.error.message)
      setRegError(result.error.message)
    }
    else if (result.message){
      console.log(result.message)
      if (result.message.startsWith("invalid address",0)){
        setRegError("invalid address")
      }
      else(
        setRegError(result.message)
      )

    }
    try{
      let curRoot = await checkForRoot(library,account)
      setCurRoot(curRoot)
      let curProxy = await checkForProxy(library,account)
      setCurProxy(curProxy)

    }
    catch(err){
      console.log(err)
    }
    if (result === true){
      setRegisterSuccess("Success")
    }
    setLoading(false)
  }

  const proveGhostFunction = async (e) =>{
    setLoading(true)
    setRegError("")
    let result = await proveGhostWeb3(library,proveGhostAddress)
    console.log(result)
    if (result.error){
      console.log(result.error.message)
      setRegError(result.error.message)
    }
    else if (result.message){
      console.log(result.message)
      if (result.message.startsWith("invalid address",0)){
        setRegError("invalid address")
      }
      else(
        setRegError(result.message)
      )
    }
    if (result === true){
      setProveSuccess("Success")
    }
    setLoading(false)
  }
  const mintWalifyProxy = async (e) =>{
    setLoading(true)
    setVerifyError("")
    setVerifySuccess("")
    let result = await mintWalifyProxyWeb3(library,account)
    console.log(result)
    if (result === true){
      console.log("good")
      setVerifySuccess("Success")
      setVerifySuccessColor("green")
    }
    if (result === false){
      console.log("failed")
      setVerifySuccess("Failed to Mint")
      setVerifySuccessColor("red")
    }
    if (result.error){
      console.log(result.error.message)
      setVerifyError(result.error.message)
    }
    else if (result.message){
      console.log(result.message)
      if (result.message.startsWith("invalid address",0)){
        setVerifyError("invalid address")
      }
      else(
        setVerifyError(result.message)
      )

    }
    setLoading(false)
  }

  const mintWalifyProxyToRoot = async (e) =>{
    setLoading(true)
    setVerifyError("")
    setVerifySuccess("")
    let result = await mintWalifyProxyToRootWeb3(library,account)
    console.log(result)
    if (result === true){
      console.log("good")
      setVerifySuccess("Success")
      setVerifySuccessColor("green")
    }
    if (result === false){
      console.log("failed")
      setVerifySuccess("Failed to Mint")
      setVerifySuccessColor("red")
    }
    if (result.error){
      console.log(result.error.message)
      setVerifyError(result.error.message)
    }
    else if (result.message){
      console.log(result.message)
      if (result.message.startsWith("invalid address",0)){
        setVerifyError("invalid address")
      }
      else(
        setVerifyError(result.message)
      )

    }
    setLoading(false)
  }
  

  const mintWalifyRoot = async (e) =>{
    setLoading(true)
    setVerifyError("")
    setVerifySuccess("")
    let result = await mintWalifyRootWeb3(library,account)
    console.log(result)
    if (result === true){
      console.log("good")
      setVerifySuccess("Success")
      setVerifySuccessColor("green")
    }
    if (result === false){
      console.log("failed")
      setVerifySuccess("Failed to mint")
      setVerifySuccessColor("red")
    }
    if (result.error){
      console.log(result.error.message)
      setVerifyError(result.error.message)
    }
    else if (result.message){
      console.log(result.message)
      if (result.message.startsWith("invalid address",0)){
        setVerifyError("invalid address")
      }
      else(
        setVerifyError(result.message)
      )

    }
    setLoading(false)
  }

  const switchNetwork = async () => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network) }]
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [networkParams[toHex(network)]]
          });
        } catch (error) {
          setError(error);
        }
      }
    }
  };

  const signMessage = async () => {
    if (!library) return;
    try {
      // console.log("here",message)
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [message, account]
      });
      setSignedMessage(message);
      setSignature(signature);
    } catch (error) {
      setError(error);
    }
  };

  const verifyMessage = async () => {
    if (!library) return;
    try {
      const verify = await library.provider.request({
        method: "personal_ecRecover",
        params: [signedMessage, signature]
      });
      setVerified(verify === account.toLowerCase());
    } catch (error) {
      setError(error);
    }
  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
    setMessage("");
    setSignature("");
    setVerified(undefined);
  };

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
  };

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, []);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = async (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAccount(accounts[0]);
        // checkForProxy(library,accounts[0])
        console.log("yo",library,accounts[0])
        let curRoot = await checkForRoot(library,account)
        setCurRoot(curRoot)
        let curProxy = await checkForProxy(library,accounts[0])
        setCurProxy(curProxy)
      };

      const handleChainChanged = (_hexChainId) => {
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        console.log("disconnect", error);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);


  useEffect(async () => {
    console.log("whatsup",account,library)
    let curRoot = await checkForRoot(library,account)
    setCurRoot(curRoot)
    let curProxy = await checkForProxy(library,account)
    setCurProxy(curProxy)
  }, [account]);

  useEffect(() => {
      if (copyMessage == ""){
        return;
      }
      else{
        setTimeout(() => {
          setCopyMessage("")
        }, 2000);
      }
  }, [copyMessage]);



  return (
    <>

<Flex color={"black"} h="20" width={"100%"} p='4' bg='white' borderRadius={"0px 0px 15px 15px"}>
  <Image src='/walify.png'></Image>
  <Spacer />
  <HStack >
          {!account ? (
            <Button background={"black"} color={"white"} onClick={connectWallet}>Connect Wallet</Button>
          ) : (
            <CopyToClipboard text={account}
          onCopy={() => {console.log("copied")
          setCopyMessage("copied")}}>
        <Box cursor={"grab"} borderRadius={"10px"} padding={"10px"} background={"black"} color={"white"} >{`${truncateAddress(account)}`}</Box>
        </CopyToClipboard>
          )}
        </HStack>

</Flex>


            {/* <Text>{`Connection Status: `}</Text>
            {account ? (
              <CheckCircleIcon color="green" />
            ) : (
              <WarningIcon color="#cd5700" />
            )}

          <Tooltip label={account} placement="right">
            <Text>{`Account: ${truncateAddress(account)}`}</Text>
          </Tooltip>
          <Text>{`Network ID: ${chainId ? chainId : "No Network"}`}</Text>
               */}
  {!account || started ? (
    <div >
    <Box  display={{ md: 'flex'}} marginLeft={{md:"auto", base:"20%"}} marginRight={{md:"auto", base:"20%"}} >
    <Spacer/>
    <VStack 
        spacing={-3} marginTop={{md: "180px", base:"90px"}} justifyContent="center" alignItems="center">
          <Text  fontSize={{md: "60", base:"35px"}} fontFamily={"roboto"}>Verify <span style={{ fontWeight: 'bold' }} >NFTs</span></Text>
          <Text   fontSize={{md: "60", base:"35px"}} fontFamily={"roboto"}>Without Ever</Text>
          <Text   fontSize={{md: "60", base:"35px"}} fontFamily={"roboto"}>Holding Them </Text>
          <Button borderWidth='2px' borderColor={"#33ff0f"} transform="translateY(50px)" w={{md:"250px", base:"200px"}} background={"white"}  margin="50px" color={"black"}  onClick={connectWallet}>
          <Text   fontSize="25px" fontFamily={"roboto"}>Get Started</Text>
          </Button>
        </VStack>
        
        <Spacer/>
        <Spacer/>
        <Spacer/>
        
        
    </Box>  
    {/* <Button  transform="translateX(-100px)" w={{md:"250px", base:"200px"}} background={"white"}  margin="50px" color={"black"}  onClick={connectWallet}>Connect Wallet</Button> */}
    
    </div>
          ) : (
      <Box display={{ md: 'flex'}} marginLeft={{md:"auto", base:"25%"}} marginRight={{md:"auto", base:"25%"}} >
        <Spacer/>

        <VStack w="230px" 
        spacing={6} marginTop={{md: "180px", base:"50px"}} justifyContent="center" alignItems="center">
        <Text>  <span>&nbsp;&nbsp;</span> {copyMessage}</Text>
        <HStack >
        <Text> Account </Text>
        <CopyToClipboard text={account}
          onCopy={() => {console.log("copied")
          setCopyMessage("copied")}}>
        <Box cursor={"grab"} padding={"5px"} borderRadius={20} borderWidth='1px' background={"white"} color={"black"} >
        {account ? (<Text>{`${truncateAddress(account)}`}</Text>) : ""}
        </Box>
        </CopyToClipboard>
        </HStack>
        
        {/* <HStack >
          <Text width={"50px"} > Ghost </Text>
          <CopyToClipboard text={curProxy}
          onCopy={() => {console.log("copied")
          setCopyMessage("copied")}}>
          <Box cursor={"grab"}  padding={"5px"} borderRadius={20} borderWidth='1px' background={"white"} color={"black"} >
            <Text>{`${truncateGhost(curProxy)}`}</Text>
          </Box>
          </CopyToClipboard>
        </HStack> */}
        
        <VStack padding={"20px"}  borderWidth='1px' borderRadius={20}>

          <Button id="mint_required" onClick={handleSequence} 
          borderWidth='2px' borderColor={"mint_required" === sequence ? "green" : ""} 
          background={"black"} color={"white"} w="95%">Mint "Required" NFT
          </Button>

          <Button id="mint_walify" onClick={handleSequence} 
          borderWidth='2px' borderColor={"mint_walify" === sequence ? "green" : ""}  
          background={"black"} color={"white"} w="95%">Mint Walify NFT
          </Button>


          {/* <Button id="prove" onClick={handleSequence} borderWidth='2px' 
          borderColor={"prove" === sequence ?  "green" : ""}  
          background={"black"} color={"white"} w="95%">Prove Ghost
          </Button>

          <Button id="registerProof" onClick={handleSequence} borderWidth='2px' 
          borderColor={"registerProof" === sequence && advanced ? "green" : ""}  
          background={"black"} color={"white"} w="95%">Reg. With Proof
          </Button> */}

          <Spacer></Spacer>
          <Spacer></Spacer>
          {!account ? (
            <Button background={"black"} color={"white"} onClick={connectWallet}>Connect Wallet</Button>
          ) : (
            <Button marginTop={"200px"} borderWidth='1px' background={"white"} color={"black"} w="75%"
            onClick={disconnect} >Logout</Button>
            
          )}

          </VStack>
        </VStack>
        <Spacer/>
        <Spacer/>
      <Spacer/>
      {"mint_required" === sequence ? 
      <VStack  w="230px" justifyContent="center" 
        spacing={6} marginTop={{md:"290px", base: "50px"}} marginRight={{md: "200px", lg:"300px" }}
        marginBottom={{md:"auto",base:"100px"}}
        >
        
        <Button onClick={mintRequired} borderWidth='2px' 
          borderColor={"#33ff0f"}  
          background={"white"} color={"black"} w="95%">Mint Required NFT
          </Button>
          <Text>This NFT is required to own in your root address to mint the Walify NFT.
          Mint with your root or mint then transfer to your root.</Text>
          {loading ? <Spinner></Spinner> : null}
          <Text color={"red"}>{regError != "" ? regError : null} </Text>
          <Text color={"green"}>{registerSuccess != "" ? registerSuccess : null} </Text>
        </VStack> : ""}

        {"mint_walify" === sequence ? 
      <VStack  w="230px" justifyContent="center" 
        spacing={6} marginTop={{md:"290px", base: "50px"}} marginRight={{md: "200px", lg:"300px" }}
        marginBottom={{md:"auto",base:"100px"}}
        >
        
        <Button onClick={mintWalifyProxy} borderWidth='2px' 
          borderColor={"#33ff0f"}  
          background={"white"} color={"black"} w="95%">Mint with Proxy TO PROXY
        </Button>
        <Button onClick={mintWalifyProxyToRoot} borderWidth='2px' 
          borderColor={"#33ff0f"}  
          background={"white"} color={"black"} w="95%">Mint with Proxy TO ROOT
        </Button>
        <Text > Root wallet: {currentRoot} </Text>
        <Button onClick={mintWalifyRoot} borderWidth='2px' 
          borderColor={"#33ff0f"}  
          background={"white"} color={"black"} w="95%">Mint with Root.
        </Button>
        
    
          <div style={{height: "100px"}}>
            {loading ? <Spinner></Spinner> : null}
            <Text color={"red"}>{verifyError != "" ? verifyError : null} </Text>
            <Text color={verifySuccessColor}>{verifySuccess != "" ? verifySuccess : null} </Text>
          </div>
        </VStack> : ""}


     
</Box>            
          )}         

    </>
  );
}
